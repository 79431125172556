<template>
  <div class="dashboard">
    <h1 class="grey--text lighten-4">Dashboard</h1>
    <v-row>
      <v-col>
        <v-card :loading="productMappersCount == null">
          <v-card-title>
            <v-icon large class="mr-3 blue--text">sync</v-icon>
            No. of Synced Products
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-h4 text-right" v-if="productMappersCount != null">{{
              productMappersCount.toLocaleString('en-US')
          }}</v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card :loading="sourceStoreCount == null">
          <v-card-title>
            <v-icon large class="mr-3 orange--text">store</v-icon>
            No. of Source Stores
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-h4 text-right" v-if="sourceStoreCount != null">{{
              sourceStoreCount.toLocaleString('en-US')
          }}</v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card :loading="destinationStoreCount == null">
          <v-card-title>
            <v-icon large class="mr-3 primary--text">store</v-icon>
            No. of Destination Stores
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-h4 text-right" v-if="destinationStoreCount != null">{{
              destinationStoreCount.toLocaleString('en-US')
          }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-icon large class="mr-3 primary--text">mdi-chart-pie</v-icon>
            Platform Installations
          </v-card-title>
          <v-divider></v-divider>
          <div v-if="platformStoresCount != null">
            <apexchart type="pie" width="380" :options="platformChartOptions" :series="platformSeries"></apexchart>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-icon large class="mr-3 primary--text">mdi-chart-pie</v-icon>
            Multi-Location Counts
          </v-card-title>
          <v-divider></v-divider>
          <div v-if="MLChartOptions != null">
            <apexchart type="pie" width="380" :options="MLChartOptions" :series="MLSeries"></apexchart>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="0" :loading="installedStores == null">
          <v-card-title>
            <v-icon large class="mr-3 green--text">add</v-icon>
            Newly Installed Stores
          </v-card-title>
          <v-divider></v-divider>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 7.5%;">Platform</th>
                  <th style="width: 27.5%;" class="text-left">Store Name</th>
                  <th style="width: 10%;">Type</th>
                  <th style="width: 27.5%;">Email</th>
                  <th style="width: 27.5%;">Installed At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="store in installedStores" :key="store.id">
                  <td>
                    <div v-if="store.platform == 'shopify'">
                      <v-img max-height="25" max-width="25" src="@/assets/images/shopify_logo.png"></v-img>
                    </div>
                    <div v-if="store.platform == 'woocommerce'">
                      <v-img max-height="25" max-width="25" src="@/assets/images/woo_logo.png"></v-img>
                    </div>
                  </td>
                  <td>
                    <a :href="'/stores/' + store.id">
                      {{ store.store_domain }}
                    </a>
                  </td>
                  <td>
                    <v-badge color="primary" v-if="store.type != 'source'" content="D"></v-badge>
                    <v-badge color="secondary" v-else content="S"></v-badge>
                  </td>
                  <td>{{ store.email }}</td>
                  <td>{{ store.created_at }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="0" :loading="jailedStoresList == null">
          <v-card-title>
            <v-icon large class="mr-3 green--text">add</v-icon>
            Jailed Stores
          </v-card-title>
          <v-divider></v-divider>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 7.5%;">Platform</th>
                  <th style="width: 27.5%;" class="text-left">Store Name</th>
                  <th style="width: 10%;">Type</th>
                  <th style="width: 27.5%;">Email</th>
                  <th style="width: 27.5%;">Installed At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="store in jailedStoresList" :key="store.id">
                  <td>
                    <div v-if="store.platform == 'shopify'">
                      <v-img max-height="25" max-width="25" src="@/assets/images/shopify_logo.png"></v-img>
                    </div>
                    <div v-if="store.platform == 'woocommerce'">
                      <v-img max-height="25" max-width="25" src="@/assets/images/woo_logo.png"></v-img>
                    </div>
                  </td>
                  <td>
                    <a :href="'/stores/' + store.id">
                      {{ store.store_domain }}
                    </a>
                  </td>
                  <td>
                    <v-badge color="primary" v-if="store.type != 'source'" content="D"></v-badge>
                    <v-badge color="secondary" v-else content="S"></v-badge>
                  </td>
                  <td>{{ store.email }}</td>
                  <td>{{ store.created_at }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="storeDeleted" color="success" timeout="1000" elevation="0" top tile>
      Store Removed Successfully!
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { FETCH_DASHBOARD_DATA } from "@/store/actions.type";
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'shops/getField',
  mutationType: 'shops/updateField',
});

export default {
  name: "Dashboard",
  computed: {
    ...mapState('reporting', ['sourceStoreCount', 'destinationStoreCount', 'productMappersCount', 'platformStoresCount', 'recentInstalledStores', 'mlStoresCount']),
    ...mapState('shops', ["storeDeleted"]),
    ...mapFields([
      "jailedStoresList",
    ])
  },
  data: function () {
    return {
      installedStores: null,
      platformChartOptions: {
        chart: {
          width: 180,
          type: 'pie',
        },
        labels: []
      },
      platformSeries: [],
      MLChartOptions: {
        chart: {
          width: 180,
          type: 'pie',
        },
        labels: ['MultiLocation', 'Non-Multilocation']
      },
      MLSeries: [],
    };
  },
  async created() {
    // 1. load dashboard api
    this.$store.dispatch(`reporting/${FETCH_DASHBOARD_DATA}`).then(() => {
      this.installedStores = this.recentInstalledStores;
      this.platformStoresCount.forEach((item) => {
        this.platformChartOptions.labels.push(item.platform);
        this.platformSeries.push(item.total);
      });
      this.MLSeries.push(this.mlStoresCount);
      this.MLSeries.push(this.destinationStoreCount - this.mlStoresCount);
    });
  },
}
</script>

<style scoped>
</style>